import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Row, Col, Card } from "reactstrap"
import Email from "../components/Email"

export default () => (
  <Layout>
    <SEO title="Kontakt" />
    <Row>
      <Col>
        <Card className="card-body">
          <h4 className="card-title">Kontakt - so erreichen Sie uns</h4>
          <h5>Per Telefon/Fax/Email</h5>
          <p className="card-text">
            Telefon: (02174) 78392-0 <br />
            Telefax: (02174) 78392-29 <br />
            Email: <Email />
          </p>
          <h5>Vor Ort</h5>
          <p className="card-text">
            Unsere Kanzlei liegt verkehrsgünstig nur zwei Minuten von der
            Autobahn A1, Abfahrt Burscheid entfernt, direkt an der Bundesstraße
            B 51, im Ortszentrum von Hilgen.
          </p>
          <div className="card-text">
            <address>
              <strong>Waerder & Lorenz</strong>
              <br />
              Kölner Str. 56
              <br />
              51399 Burscheid
            </address>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2505.1954242411125!2d7.157074279550432!3d51.10485444797719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bed56864378199%3A0x6dd2e704b96ebb16!2sDipl.-Finanzwirt+Hans+Peter+Waerder+Dipl.-%C3%96konom+Jochen+Lorenz+Steuerberater!5e0!3m2!1sde!2sde!4v1552049102763"
            height="450"
            frameBorder="0"
            style={{ border: 0, width: "100%" }}
            allowFullScreen
            title="Google Maps"
          />
        </Card>
      </Col>
    </Row>
  </Layout>
)
